import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

const HeaderCol = ({ children }) => {
  return (
    <Col xs={11} sm={10} md={9} lg={8} xl={7}>
      {children}
    </Col>
  );
};

HeaderCol.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderCol;
